import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'I\'m an e-commerce / full stack JavaScript developer proficient in BigCommerce, Angular 2+, React, Node.js. I have strong knowledge of HTML5, CSS3, JavaScript ES6 +, JQuery, Angular 2+, React, BigCommerce, WordPress and experience working with Salesforce Commerce Cloud, Salesforce Marketing Cloud, Laravel, Sitefinity, AWS Lambda functions, Google functions, Google APIs, Express, NPM, Webpack, Babel, Gulp, GitHub, BitBucket, Azure DevOps, Bootstrap, Foundation, SCSS, Sass, PostCSS, PHP, and MySQL...' },
                { name: 'keywords', content: 'site, web' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
